import { get, post, delete0 } from '@/utils/request'

const videoApi = {
  videoPostApi: '/api/content/post/video',
  updateVideoScopeApi: '/api/content/post/video/update/scope',
  updateVideoStatusApi: '/api/content/post/video/update/status',
  updateVideoInfoApi: '/api/content/post/video/update/info',
  updateVideoCoverApi: '/api/content/post/video/update/cover',
  updateVideoFileApi: '/api/content/post/video/update/file',
  videoResourceApi: '/api/content/post/video/resource',
  convertVideoApi: '/api/content/post/video/convert',

  videoCategoryApi: '/api/content/video/categories',
  categoryVideoApi: '/api/content/video/category',
  categoryShortVideoApi: '/api/content/video/category/short',
  userVideoPostApi: '/api/content/video/user',
  tagVideoPostApi: '/api/content/video/tag',
  videoInfoApi: '/api/content/video/detail',
  videoUrlApi: '/api/content/video/url',

  videoErrorReportApi: '/api/content/video/report',
  videoDownloadApi: '/api/content/video/download',
  cacheBiliApi: '/api/content/video/cache/bili',
  shortUrlApi: '/api/content/share',

  videoRecommendApi: '/api/content/video/recommend',
  similarVideoApi: '/api/content/video/similar',
  bannerVideoApi: '/api/content/video/hot',
  userContentDataApi: '/api/content/userdata',
  videoTimelineApi: '/api/content/video/timeline'
}

// *********************************************************************************************************************
// 添加视频贴
export function addVideoPost(video) {
  return post(videoApi.videoPostApi, video)
}

// 更新视频可见范围
export function updateVideoScope(data) {
  return post(videoApi.updateVideoScopeApi, data)
}

// 更新视频状态
export function updateVideoStatus(data) {
  return post(videoApi.updateVideoStatusApi, data)
}

// 更新视频信息
export function updateVideoInfo(data) {
  return post(videoApi.updateVideoInfoApi, data)
}

// 更新视频封面
export function updateVideoCover(data) {
  return post(videoApi.updateVideoCoverApi, data)
}

// 更新视频文件
export function updateVideoFile(data) {
  return post(videoApi.updateVideoFileApi, data)
}

// 删除视频贴
export function deleteVideoPost(videoId) {
  return delete0(videoApi.videoPostApi + '/' + videoId)
}

// 获取视频贴列表
export function getVideoPosts(page) {
  return get(videoApi.videoPostApi + '?page=' + page)
}

// 获取视频贴详情
export function getVideoPost(videoId) {
  return get(videoApi.videoPostApi + '/' + videoId)
}

// 获取视频资源
export function getVideoResource(videoId) {
  return get(videoApi.videoResourceApi + '/' + videoId)
}

// 视频转码
export function convertVideo(videoId) {
  return post(videoApi.convertVideoApi + '/' + videoId)
}

// *********************************************************************************************************************
// 获取视频分类
export function videoRegion() {
  return get(videoApi.videoCategoryApi)
}

export function videoCategories() {
  return get(videoApi.videoCategoryApi)
}

// 获取分类视频
export function categoryVideos(categoryId, page) {
  return get(videoApi.categoryVideoApi + '?categoryId=' + categoryId + '&page=' + page)
}

export function categoryShortVideos(categoryId, page) {
  return get(videoApi.categoryShortVideoApi + '?categoryId=' + categoryId + '&page=' + page)
}

// 获取用户视频
export function getUserVideos(userId, page) {
  return get(videoApi.userVideoPostApi + '?userId=' + userId + '&page=' + page)
}

// 获取相同标签的视频
export function getTagVideos(tag, page) {
  return get(videoApi.tagVideoPostApi + '?tag=' + tag + '&page=' + page)
}

// 获取视频详情
export function videoInfo(videoId) {
  return get(videoApi.videoInfoApi + '/' + videoId)
}

// 获取视频 URL
export function videoUrl(videoId) {
  return get(videoApi.videoUrlApi + '/' + videoId)
}

// *********************************************************************************************************************
// 报告视频错误
export function videoErrorReport(data) {
  return post(videoApi.videoErrorReportApi, data)
}

// 下载视频
export function downloadVideo(videoId) {
  return get(videoApi.videoDownloadApi + '/' + videoId)
}

// 缓存 bili 视频
export function cacheBiliVideo(bvId) {
  return post(videoApi.cacheBiliApi + '/' + bvId)
}

// 获取分享视频的短链接
export function getShortUrl(bvId) {
  return get(videoApi.shortUrlApi + '?videoId=' + bvId)
}

// ********************************************************************************************************************
// 获取推荐视频
export function videoRecommend(nextId) {
  return get(videoApi.videoRecommendApi + '?nextId=' + nextId)
}

// 获取相似视频
export function similarVideo(videoId) {
  return get(videoApi.similarVideoApi + '?videoId=' + videoId)
}

// 获取 banner 视频
export function getBannerVideo() {
  return get(videoApi.bannerVideoApi)
}

// 获取用户内容统计
export function getUserContentData(userId) {
  return get(videoApi.userContentDataApi + '?userId=' + userId)
}

// 用户视频时间线
export function videoTimeline(nextId) {
  return get(videoApi.videoTimelineApi + '?nextId=' + nextId)
}
